import React from "react";
import { useLocale } from "../../hooks";
import { useStaticQuery, graphql } from 'gatsby'
export const useSiteQuery = () => {
    const locale = useLocale();
    const {
        allContentfulGlobalSite: { edges }
    } = useStaticQuery(
        graphql`
            query globalSite{
                allContentfulGlobalSite {
                    edges {
                        node {
                            node_locale
                            sitemapTitle
                            headerMenu {
                                title
                                slug
                                subMenuLink {
                                    title
                                    slug
                                  }
                            }
                            logo {
                                image {
                                    title
                                    fluid{
                                    ...GatsbyContentfulFluid_withWebp_noBase64
                                    }
                                }
                            }
                            headerMenuRight {
                                title
                                slug
                                subMenuLink {
                                    title
                                    slug
                                  }
                            }
                            searchPageSlug
                            footerContactUsLink {
                                title
                                slug
                            }
                            footerSiteLanguages {
                                title
                                slug
                            }
                            footerMenuItems {
                                title
                                slug
                            }
                            footerSocialIcons {
                                title
                                slug
                                image {
                                    fluid {
                                      ...GatsbyContentfulFluid_withWebp_noBase64
                                    }
                                }
                            }
                            footerUnileverLogo {
                                title
                                slug
                                image {
                                    fluid {
                                        ...GatsbyContentfulFluid_withWebp_noBase64
                                    }
                                  }
                            }
                            copyrightText
                            footerCopyrightDescription {
                                footerCopyrightDescription
                            }
                            allFilterSections{
                                title
                                filterType
                            }
                            articlesGlobalIcon{
                                title
                                fluid {
                                    ...GatsbyContentfulFluid_withWebp_noBase64
                                }
                            }
                            ourPurpose
                            ourPurposeText{
                                raw
                            }
                            sitemapDirectLink{
                                title
                                slug
                                target {
                                    ... on ContentfulPageCategoryProducts {
                                        slug
                                    }
                                    ... on ContentfulPageHelpCenter {
                                        slug
                                    }
                                }          
                            }
                            signUpBackgroundImage{
                                title
                                fluid {
                                    ...GatsbyContentfulFluid_withWebp_noBase64
                                }
                            }
                            seoImageGlobal{
                                title
                                fluid {
                                    ...GatsbyContentfulFluid_withWebp_noBase64
                                }
                            }
                            signUpSubHeading
                            signUpEmail
                            signUpFirstName
                            signUpLastName
                            signUpHeading
                            signUpCondition3
                            signUpCondition2
                            signUpCondition1
                            signUpCondition {
                                raw
                            }
                            signUpSubmit
                            image{
                                title
                                fluid {
                                    ...GatsbyContentfulFluid_withWebp_noBase64
                                }
                            }
                            productImage{
                                title
                                fluid {
                                    ...GatsbyContentfulFluid_withWebp_noBase64
                                }
                            }
                            products {
                                slug
                                thumbnailImage{
                                    title
                                    fluid {
                                        ...GatsbyContentfulFluid_withWebp_noBase64
                                    }
                                }
                                title
                                subTitle
                            }
                            articles {
                                thumbnailImage{
                                    title
                                    fluid {
                                        ...GatsbyContentfulFluid_withWebp_noBase64
                                    }
                                }
                                title
                                slug
                                seoDescription {
                                    seoDescription
                                }
                            }
                            signUpPopupTitle
                            signUpPopupDescription
                            signUpPopupButtonText
                            signUpPopupButtonLink
                            signUpPopupImage{
                                title
                                fluid(maxWidth: 300, quality: 60) {
                                    ...GatsbyContentfulFluid_withWebp_noBase64
                                }
                            }
                            signUpPopupBackgroundImage{
                                title
                                fluid {
                                    ...GatsbyContentfulFluid_withWebp_noBase64
                                }
                            }
                            featureProductBackgroundImage{
                                title
                                fluid(maxWidth:2000 quality: 100) {
                                    ...GatsbyContentfulFluid_withWebp_noBase64
                                }
                            }
                            showAllProducts
                        }
                    }
                }
            } 
       `
    )

    const localeData = locale
        ? edges.filter(({ node }) => node.node_locale === locale)
        : edges.filter(({ node }) => node.node_locale === 'en-EG')
    const site = localeData.reduce((acc, val) => ({ ...acc, ...val.node }), {})
    return site

}